import React from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ClearIcon from '@material-ui/icons/Clear';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    padding: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function Converter(props) {
  const classes = useStyles();

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleCopy = async() => {
    try {
      await navigator.clipboard.writeText(props.value);
      setSnackbarMessage('Copied to clipboard.');
      setSnackbarOpen(true);
    } catch (err) {
      setSnackbarMessage(err.toString());
      setSnackbarOpen(true);
    }
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h5">{props.title}</Typography>
        <div className={classes.grow} />
        <IconButton
          onClick={handleCopy}
        >
          <FileCopyIcon />
        </IconButton>
        <IconButton
          onClick={props.onClear}
        >
          <ClearIcon />
        </IconButton>
      </Toolbar>
      <TextField
        autoFocus={props.autoFocus}
        multiline
        fullWidth
        placeholder={props.placeholder}
        rows={16}
        value={props.value}
        helperText={props.helperText ? props.helperText : ' '}
        error={!!props.helperText}
        onChange={props.onChange}
        inputProps={{ spellCheck: false }}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        message={snackbarMessage}
      />
    </Paper>
  )
};
