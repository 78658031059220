import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Converter from './Converter';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Converters() {
  const classes = useStyles();
  const [decodedText, setDecodedText] = React.useState('');
  const [encodedText, setEncodedText] = React.useState('');
  const [decodedHelperText, setDecodedHelperText] = React.useState('');
  const [encodedHelperText, setEncodedHelperText] = React.useState('');

  const handleDecodedChange = (newValue) => {
    try {
      setDecodedText(newValue);
      setEncodedText(encodeURIComponent(newValue));
      if (encodedHelperText) setEncodedHelperText('');
      if (decodedHelperText) setDecodedHelperText('');
    } catch (err) {
      setDecodedHelperText(err.toString());
      setEncodedHelperText('');
    }
  };

  const handleEncodedChange = (newValue) => {
    try {
      setEncodedText(newValue);
      setDecodedText(decodeURIComponent(newValue));
      if (encodedHelperText) setEncodedHelperText('');
      if (decodedHelperText) setDecodedHelperText('');
    } catch (err) {
      setEncodedHelperText(err.toString());
      setDecodedHelperText('');
    }
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={6}>
        <Converter
          autoFocus
          title="Decoded"
          value={decodedText}
          helperText={decodedHelperText}
          onChange={(event) => handleDecodedChange(event.target.value)}
          onClear={() => handleDecodedChange('')}
        />
      </Grid>
      <Grid item xs={6}>
        <Converter
          title="Encoded"
          value={encodedText}
          helperText={encodedHelperText}
          onChange={(event) => handleEncodedChange(event.target.value)}
          onClear={() => handleEncodedChange('')}
        />
      </Grid>
    </Grid>
  );
}
